import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { Box, Container, Tooltip, Typography } from "@mui/material";
import { FooterContainer } from "./footer_style";
import { useEffect, useState } from "react";

const Footer = () => {
  const [iconSize, setIconSize] = useState("lg");
  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setIconSize("sm");
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setIconSize("lg");
      } else {
        setIconSize("lg");
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const socialLinks = [faFacebook, faInstagram, faTwitter, faLinkedin];
  const socialLinkNames = ["Facebook", "Instagram", "Twitter", "Linkdin"];

  return (
    <FooterContainer maxWidth="xxl">
      <Box>
        <Typography
          fontSize={{ sm: "1rem", md: "1.2rem" }}
          variant="subtitle1"
          maxWidth="md"
          fontFamily={"inherit"}
        >
          &copy; 2035 by Social Lights{" "}
        </Typography>
        <Typography
          fontSize={{ sm: "1rem", md: "1.2rem" }}
          variant="subtitle1"
          maxWidth="md"
          fontFamily={"inherit"}
          lineHeight={1}
        >
          Powered and Secured by GPTI{" "}
        </Typography>
      </Box>
      <Box>
        <Container>
          <Typography
            fontSize={{ sm: "1rem", md: "1.2rem" }}
            variant="subtitle1"
            fontFamily={"inherit"}
            maxWidth="md"
          >
            Be a SociaLight and Follow Us:
          </Typography>
        </Container>
        <Container sx={{ marginTop: "0.5em" }}>
          {socialLinks.map((value, ind) => {
            return (
              <Tooltip title={socialLinkNames[ind]} arrow>
                <Typography variant="h5" component={"span"} mr={1}>
                  {" "}
                  <FontAwesomeIcon icon={value} size={iconSize} />
                </Typography>
              </Tooltip>
            );
          })}
        </Container>
      </Box>
    </FooterContainer>
  );
};
export default Footer;

import { Box } from "@mui/material";
import TeamImg from "../../assests/TeamLogo2.jpg";
import "./About.css";

const About2 = () => {
  return (
    <Box
      className="AI-Main"
      sx={{
        paddingInline: "24px",
        paddingBlock: "48px",
        boxSizing: "border-box",
        width: "100%",
        position: "relative",
      }}
    >
      <div className="AI-Container">
        <div className="AI-Row" style={{alignItems:"center"}}>
          <div className="AI-Col1">
            {/* <div className="AI-Title">
              <h4>Our Vision</h4>
            </div> */}
            <h1 className="AI-Heading">
             
            Meet Our Experienced AIeam Members

            </h1>
            <p className="AI-Body">
              AIempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet
            </p>
            <p className="AI-Body">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet
            </p>
            <p className="AI-Body">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet
            </p>
          </div>
          <div className="AI-Col2">
              <div className="AI-ImgContainer">
                <img src={TeamImg} />
              </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default About2;

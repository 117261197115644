import "./baseTemplate.css";
import {
  Box,
  Button,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import AboutImg from "../../../assests/AboutImg.webp"
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import BgImage from "../../../assests/CUBGImg.svg"
const BaseTemplate1 = (props) => {
  var Img=require(`../../../assests/${props.img}`)
  const socialLinks = [faFacebook, faInstagram, faTwitter, faLinkedin];
  const socialLinkNames = ["Facebook", "Instagram", "Twitter", "Linkdin"];
  return (
    <Box
      id="AU-Container"
      sx={{
        padding: "48px 24px",
        backgroundImage:props.reverse?`url(${BgImage})`:""
      }}
    >
      <Box
        id="AU-Wrapper"
        sx={{
          padding: "48px 12px",
          backgroundImage:props.reverse?"":"linear-gradient(to right,rgb(14,36,83),rgb(14, 36, 83));"

        }}
      >
        <Box
          id="AU-Row"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexFlow:props.reverse?"row-reverse":"row",
            flexWrap: "wrap",
            marginBlock: "-48px",
            marginInline: "-24px",
          }}
        >
          <Box
            ID="AU-Col1"
            sx={{
              width: { xs: "100%", md: "40%" },
              marginTop: "48px",
              paddingInline: "24px",
            }}
          >
            <Box ID="AU-ImageCotainer" sx={{ position: "relative" }}>
              <img src={Img} width={"100%"} id="AboutImg" />
            </Box>
          </Box>
          <Box
            className="AU-Col2"
            sx={{
              width: { xs: "100%", md: "50%" },
              marginTop: "48px",
              paddingInline: "24px",
            }}
          >
            <Box
              component={Button}
              Id={"AU-LableName"}
              mb={"1rem"}
              sx={{
                borderRadius: "800px",
                backgroundColor: "rgb(249,124,92)",
                color: "white",
              }}
            >
             {props.slideName}
            </Box>
            <Typography
              variant="h1"
              fontSize={"40px"}
              fontWeight={700}
              sx={{ marginBlockEnd: "2rem", color: "white" }}
            >
              {props.heading}
            </Typography>
            {/* <Typography
              variant="body1"
              sx={{ marginBlockEnd: "2rem", color: "white" }}
            >
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBlockEnd: "2rem", color: "white" }}
            >
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBlockEnd: "2rem", color: "white" }}
            >
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet
            </Typography> */}
            {
              props["p"].map((val,ind)=>{
                return  (<Typography
                variant="body1"
                sx={{ marginBlockEnd: "2rem", color: "white" }}
              >
                {val}
              </Typography>)
              })
            }

            { props.bullets?(<Grid
              container
              spacing={2}
              sx={{
                marginBlockEnd: "2rem",
                color: "white",
                padding: 0,
                width: "100%",
                marginInline: 0,
              }}
            >
              <Grid container alignItems={"center"} item xs={12} sm={6}>
                <Grid item xs={1}>
                  <FontAwesomeIcon icon={faCheck} color="rgb(249,124,92)" />
                </Grid>
                <Grid>
                  <Typography variant="h6">Work Through</Typography>
                </Grid>
              </Grid>
              <Grid container alignItems={"center"} item xs={12} sm={6}>
                <Grid item xs={1}>
                  <FontAwesomeIcon icon={faCheck} color="rgb(249,124,92)" />
                </Grid>
                <Grid>
                  <Typography variant="h6">24/7 Support</Typography>
                </Grid>
              </Grid>
              <Grid container alignItems={"center"} item xs={12} sm={6}>
                <Grid item xs={1}>
                  <FontAwesomeIcon icon={faCheck} color="rgb(249,124,92)" />
                </Grid>
                <Grid>
                  <Typography variant="h6">Professional Staff</Typography>
                </Grid>
              </Grid>
              <Grid container alignItems={"center"} item xs={12} sm={6}>
                <Grid item xs={1}>
                  <FontAwesomeIcon icon={faCheck} color="rgb(249,124,92)" />
                </Grid>
                <Grid>
                  <Typography variant="h6">Fair Prices</Typography>
                </Grid>
              </Grid>
            </Grid>):""}

            {props.readMore?(<Box
              className="Read-More"
              sx={{
                display: "flex",
                alignItems: "center",
                paddingInline: "1rem",
                marginBlockEnd: "2rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                id="Read-Me-button"
                variant="contained"
                sx={{ marginBlockEnd: { xs: "1rem", sm: 0 } }}
              >
                ReadMe More
              </Button>
              <Box
                id="AU-LinkWrapper"
                sx={{ marginInlineStart: { sm: "2rem" } }}
              >
                {socialLinks.map((value, ind) => {
                  return (
                    <Tooltip title={socialLinkNames[ind]} arrow>
                      <Typography variant="h5" component={"span"} mr={1}>
                        {" "}
                        <FontAwesomeIcon icon={value} color="rgb(249,124,92)" />
                      </Typography>
                    </Tooltip>
                  );
                })}
              </Box>
            </Box>):""}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BaseTemplate1;

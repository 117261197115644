import homepageImg from "../../assests/homePageImg.png";
import "./homePage.css";
import {
  BodyContainer,
  Button,
  ButtonContianer,
  Content,
  Heading,
  ImageColumn,
  MainContainer,
  Row,
  TextColumn,
} from "./homePage_style";

const HomePage = () => {
  return (
    <MainContainer id="MainContainer">
      <BodyContainer>
        <Row>
          <TextColumn>
            <Heading id="Heading">Computer Vision For Your Business</Heading>
            <Content id="Content">
              Tempor rebum no at dolore lorem clita rebum rebum ipsum rebum
              fhbhjfvgvf uhfv fhj yufvihfuyf
            </Content>
            <ButtonContianer id="ButtonContainer">
              <Button id="Button">Read More</Button>
              <Button id="Button">Contact Us</Button>
            </ButtonContianer>
          </TextColumn>
          <ImageColumn id="ImageColumn">
            <div>
              <img src={homepageImg} />
            </div>
          </ImageColumn>
        </Row>
      </BodyContainer>
    </MainContainer>
  );
};

export default HomePage;

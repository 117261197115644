import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";

export const FooterContainer=styled(Container)({
    backgroundColor:"rgb(60,60,77)",
    color:"white",
    display:"flex",
    fontFamily:["Times New Roman", "Times", "serif"],
    justifyContent:'space-around',
    flexWrap:"wrap-reverse",
    height:"9rem",
    alignContent:'center'
})
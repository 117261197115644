import styled from "styled-components";

export const MainContainer = styled.div`
  padding-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
  // background-image: linear-gradient(to left, rbg(14,36,83),rbg(14,36,83));
  margin-top: -70px;
`;

export const BodyContainer = styled.div`
  background-image: url("../../assests/homePagebg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const TextColumn = styled.div`
  width: 50%;
  margin-block-start: 1rem;
  display: flex;
  padding-left: 0.2rem;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  transition: all 3s;
`;

export const Heading = styled.div`
  color: white;
  width: 70%;
  font-size: 56.63px;
  font-weight: 700;
  margin-block-end: 1.5rem;
  font-family: Ubuntu, sans-serif;
  transition: all 5s;
`;

export const Content = styled.div`
  width: 70%;
  font-size: 20.06px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin-block-end: 1.5rem;
  transition: all 3s;
  color: white;
`;

export const ButtonContianer = styled.div`
  width: 70%;
  box-sizing: border-box;
  margin-block-end: 1rem;
`;

export const Button = styled.button`
  transition: all 3s;
  height: 58px !important;
  width: 185px;
  font-size: 16px;
  border-radius: 400px;
  background-color: rgb(249, 124, 92);
  color: white;
  border-style: solid;
  border: none;
  cursor: pointer;
  font-weight: 500;
  margin-right: 16px;
  padding-bottom: 16px;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 16px;
  text-align: center;
  // &:hover {
  //   background-image: linear-gradient(to right, blue, red);
  //   color: white;
  // }
`;

export const ImageColumn = styled.div`
  height: 100%;
  text-align: center;
  box-sizing: border-box;
  display: flex;
`;

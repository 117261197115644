import "./profileCard.css";
import {
  PC_Contianer,
  PC_ImgContainer,
  PC_Logo,
  PC_ND_Wrapper,
  PC_Name,
  PC_Designation,
  PC_SocialLinkWrapper,
} from "./profileCard_style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const ProfileCard = (props) => {
  
  const socialLinks = [faFacebook, faInstagram, faTwitter, faLinkedin];
  return (
    <PC_Contianer id="tom" style={{height:props.style.height,width:props.style.width,marginBlockEnd:props.marginBE}}>
      <PC_ImgContainer id="pc_ImgContainer"  style={{height:props.style.ImageWidth,width:props.style.ImageWidth}}>
        <PC_Logo src={require("../../../assests/" + props.data.Image)} />
      </PC_ImgContainer>
      <PC_ND_Wrapper>
        <PC_Name>{props.data.Name}</PC_Name>
        <PC_Designation> {props.data.Position}</PC_Designation>
      </PC_ND_Wrapper>
      {
        props.links?(
      <PC_SocialLinkWrapper>
        {socialLinks.map((value, ind) => {  

          return (
            <FontAwesomeIcon
              icon={value}
              size="2x"
              color="blue"
              className="PC-Icon"
            ></FontAwesomeIcon>
          );
        })}
      </PC_SocialLinkWrapper>):""
}
    </PC_Contianer>
  );
};

export default ProfileCard;


import {
  Box,
  Typography,
} from "@mui/material";
import Img from "../../assests/HomePageSlide2_1.png"
const HomepageSlide3 = (props) => {
  return (
    <Box
      id="HSP3-Container"
      sx={{
        padding: "48px 24px",
        
      }}
    >
      <Box
        id="HSP3-Wrapper"
        sx={{
          padding: "48px 12px",
          backgroundImage:props.reverse?"":"linear-gradient(to right,rgb(14,36,83),rgb(14, 36, 83));"

        }}
      >
        <Box
          id="HSP3-Row"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexFlow:props.reverse?"row-reverse":"row",
            flexWrap: "wrap",
            marginInline: "-24px",
          }}
        >
          <Box
            ID="HSP3-Col1"
            sx={{
              width: { xs: "100%", md: "40%" },
              paddingInline: "24px",
            }}
          >
            <Box ID="HSP3-ImageCotainer">
              <img src={Img} width={"100%"} id="AboutImg" />
            </Box>
          </Box>
          <Box
            className="HSP3-Col2"
            sx={{
              width: { xs: "100%", md: "50%" },
              marginTop: "48px",
              paddingInline: "24px",
            }}
          >
            {/* <Typography
              variant="h1"
              fontSize={"40px"}
              fontWeight={700}
              sx={{ marginBlockEnd: "2rem", color: "white" }}
            >
              Make Your Bussiness Smater with your Solution
            </Typography> */}
            <Typography
              variant="body1"
              sx={{ fontSize:"1.2rem", marginBlockEnd: "2rem", color: "white" ,textAlign:"justify"}}
            >
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet.
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBlockEnd: "2rem", color: "white" ,textAlign:"center",fontSize:"1.2rem"}}
            >
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet.
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomepageSlide3;

import { useEffect } from "react";
import ProfileCard from "../CustomComponents/profileCard/ProfileCard"
import { useState } from "react";

const Team2=()=>{
    var data = require("./TeamData.json");
    var [style,setStyle]=useState({height:"",width:"",ImageWidth:""})
    useEffect(()=>{
        const SetCardsize=()=>{
            console.log("widht",window.innerWidth)
            if(window.innerWidth>=1536)
            {
                setStyle((prev)=>{
                    return{...prev,height:"400px",width:"300px",ImageWidth:"250px",bgColor:"black"}
                })
    
            }
            if(window.innerWidth<1536 && window.innerWidth>=600)
            {
                setStyle((prev)=>{
                    return{...prev,height:"300px",width:"200px",ImageWidth:"150px",bgColor:"black"}
                })
    
            }
            if(window.innerWidth<600 && window.innerWidth>=0)
            {
                setStyle((prev)=>{
                    return{...prev,height:"350px",width:"250px",ImageWidth:"200px",bgColor:"black"}
                })
    
            }
            
            
        }
        SetCardsize()

        window.addEventListener("resize",SetCardsize)

        return(()=>{window.removeEventListener("resize",SetCardsize)})
    },[])

   
return(
    <div className="T2-Container">
        <div className="T2-Title">
              <h4># Meet Our Experienced Team Members</h4>
            </div>
        <div className="T2-Row">

        






        {/* <div className="T2-Col1">
        <div className="T-Title">
              <h4>Our Team</h4>
            </div>
            <h1 className="T-Heading">
              
                Meet Our Experienced Team Members
                
            </h1>
            <p className="T-Body">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet
            </p>
            <p className="T-Body">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet
            </p>
            
        </div> */}
        <div className="T2-Col2">
        <div className="T2-cardWrapper">
        <ProfileCard data={data["1"]} style={style} marginBE={"1rem"} />
            <ProfileCard data={data["2"]} style={style} marginBE={"1rem"} />
            <ProfileCard data={data["1"]} style={style} marginBE={"1rem"}/>
            <ProfileCard data={data["2"]} style={style} marginBE={"1rem"}/>
            </div>
            {/* <div className="T2-cardWrapper">
                <div className="T2-CardCol1">
                    <div className="T2-CardCol1Row">
            <ProfileCard data={data["1"]} style={style} />
            <ProfileCard data={data["2"]} style={style} marginBS={"2rem"}/>
            </div>
            </div>
            <div className="T2-CardCol2">
            <div className="T2-CardCol2Row">
            <ProfileCard data={data["3"]}  style={style}/>
            <ProfileCard data={data["4"]} style={style} marginBS={"2rem"}/>
            </div>
            </div>
            </div> */}
            </div>
        </div>
    </div>
)
}
 export default Team2
import emailjs from "@emailjs/browser"

const Emailjs=(FormData)=>{
        var templeteParams = { name: "", email: "", subject: "", message: "" };
        templeteParams.name = FormData["InputBox name"];
        templeteParams.email = FormData["InputBox email"];
        templeteParams.subject = FormData["InputBox subject"];
        templeteParams.message = FormData["InputBox message"];
        const publicKey=process.env.REACT_APP_EMAILKEY
        const serviceID=process.env.REACT_APP_SERVICEID
        const templateID=process.env.REACT_APP_TEMPLATEID
         return (emailjs
          .send(
            serviceID,
            templateID,
            templeteParams,
            publicKey
          ))
          // .then(
          //   function (response) {
          //     console.log("SUCCESS!", response.status, response.text);
          //     window.location.reload()
          //   },
          //   function (error) {
          //     console.log("FAILED...", error);
          //   }
          // );
}


export default Emailjs
import "./homePage.css";
import Img from "../../assests/HPS4_1_3.jpg";

const HomepageSlide4 = () => {
  return (
    <div className="HPS4-Container">
      <div className="HPS4-Wrapper">
        <div className="HPS4-Row">
          <img src={Img}></img>
          <h4>Empower your Journey with Vision and Language Mastery</h4>
          <p>
            Step into AltGAN - where innovation converges with intelligence,
            unleashing the potential of computer vision and language models for
            unparalleled digital transformations
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomepageSlide4;

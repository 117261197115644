import { Widgets } from "@mui/icons-material";
import "./TextFeild.css"
import { InputContainer } from "./textfeild_style";
const TextField=(props)=>{
    return(
<InputContainer style={{width:props.width,height:props.height}}>
    <input className={`InputBox ${props.name}`} type="text" required onChange={props.handleFeildChange}/>
    <span className="InputName">{props.name}</span>
    </InputContainer>
    )
}

export default TextField;
import Img1 from "../../assests/HomePageSlide2_1.png";
import "./homePage.css";
const HomepageSlide2 = () => {
  return (
    <div className="HPS2-Container">
      <div className="HPS2-RowWrapper">
        <div className="HPS2-Row1">
          <img src={Img1}></img>
          <p>
            Catering to a diverse clientele, from startups to enterprises, we
            specialize in customizing our integration services to meet your
            unique needs. Catering to a diverse clientele, from startups to enterprises, we
            specialize in customizing our integration services to meet your
            unique needs Catering to a diverse clientele, from startups to enterprises, we
            specialize in customizing our integration services to meet your
            unique needs
          </p>
        </div>
        <div className="HPS2-Row2">
            <img src={Img1} alt="" />
            <p>
            Catering to a diverse clientele, from startups to enterprises, we
            specialize in customizing our integration services to meet your
            unique needs. Catering to a diverse clientele, from startups to enterprises, we
            specialize in customizing our integration services to meet your
            unique needs Catering to a diverse clientele, from startups to enterprises, we
            specialize in customizing our integration services to meet your
            unique needs
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomepageSlide2;

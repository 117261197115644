import "./About.css";
import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import homeImg from "../../assests/homePageImg.png";
import homeBg from "../../assests/homePagebg.png";

const AboutLanding = (props) => {
  return (
    <Box
      id="AL-Container"
      sx={{
        backgroundImage: `url(${homeBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundColor: `rgb(14,36,83)`,
      }}
    >
      <Container
        className="Al-Wrapper"
        sx={{
          paddingInline: "12px",
          paddingTop: "48px",
        }}
      >
        <Box
          id="AL-Row"
          sx={{
            marginTop: "-48px",
            marginInline: "-24px",
            paddingTop: "48px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <Box
            className="AL-Col1"
            sx={{
              marginInline: "24px",
              marginBlock: "48px",
              alignSelf: "center",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "38px", md: "48px", lg: "56px" },
                fontWeight: "700",
              }}
              color={"white"}
              gutterBottom
            >
              {props.PageName}
            </Typography>
            <Breadcrumbs aria-label="breadcrumb" color="white">
              <Link style={{ textDecoration: "none", color: "white" }} href="/">
                Home
              </Link>
              <Link style={{ textDecoration: "none", color: "white" }} href="/">
                Page
              </Link>
              <Typography color="rgb(249, 124, 92)">
                {props.PageName}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box
            className="AL-Col2"
            sx={{ marginInline: "24px", marginBlock: "48px" }}
          >
            <img src={homeImg} style={{ objectFit: "contain" }} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutLanding;

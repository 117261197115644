import Footer from "../../components/Footer/Footer";
import ResponsiveAppBar from "../../components/Header/Header";

const HFContainer = ({ PageName, children }) => {
  var tabTile = document.getElementById("Tab-Title");
  tabTile.innerHTML = PageName;
  return (
    <>
      <ResponsiveAppBar />
      {children}
      <Footer />
    </>
  );
};
export { HFContainer };

import { useEffect, useState } from "react";
import ContactImg from "../../assests/Illustration (1).svg";
import TextField from "../CustomComponents/textFeilds/TextFeild";
import "./contact.css";
import { Emailjs } from "../../services";
import { SyncLoader } from "react-spinners";

const ContactPage = () => {
  const [FeildSize, setFeildSize] = useState("40%");
  const [FormData, setFormData] = useState({});
  const [sentUpdate, setSentupdate] = useState({ status: -2, message: "" ,bgColor:"",color:""});

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setFeildSize((prev) => {
          return "100%";
        });
      } else {
        setFeildSize((prev) => {
          return "40%";
        });
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const validateDetails = (FormData) => {
    if (
      FormData["InputBox name"] &&
      FormData["InputBox email"] &&
      FormData["InputBox subject"] &&
      FormData["InputBox subject"] != ""
    )
      return true;
    else return false;
  };

  const handleFeildChange = (e) => {
    if (e.target.className == "CP-FormButton") {
      if (validateDetails(FormData)) {
        e.preventDefault();
        setSentupdate((prev) => {
          return { ...prev, status: 0 };
        });
        Emailjs(FormData).then(
          function (response) {
            setSentupdate((prev) => {
              setInterval(()=>{
                window.location.reload()
              },1000)
              return { ...prev, status: 1, message: "message Sent",bgColor:"rgb(25, 135, 84)",color:"white" };
            });
          },
          function (error) {
            setSentupdate((prev) => {
              setInterval(()=>{
                window.location.reload()
              },1000)
              return {
                ...prev,
                status: -1,
                message: "message sent un-successful",
                bgColor:"lightred",color:"white"
              };
            });
          }
        );
      } else {
      }
    } else {
      setFormData((prev) => {
        return { ...prev, [e.target.className]: e.target.value };
      });
    }
  };
  return (
    <div className="CP-Main">
      <div className="CP-Container">
        <div className="CP-Col1">
          <div className="CP-ImgContainer">
            <img src={ContactImg} width={"150px"} />
          </div>
          <div className="CP-Heading">
            <h2>If You Have Any Query, Please Contact Us</h2>
          </div>
        </div>
        <div className="CP-Col2">
          <form className="CP-FormContainer">
            <TextField
              name="name"
              width={FeildSize}
              handleFeildChange={handleFeildChange}
            />
            <TextField
              name="email"
              width={FeildSize}
              handleFeildChange={handleFeildChange}
            />
            <TextField
              width="90%"
              name="subject"
              handleFeildChange={handleFeildChange}
            />
            <TextField
              width="90%"
              height="150px"
              name="message"
              handleFeildChange={handleFeildChange}
            />
            <button className="CP-FormButton" onClick={handleFeildChange} style={{backgroundColor:sentUpdate.bgColor,color:sentUpdate.color}}>
              {sentUpdate.status == -2 ? (
                "Send Message"
              ) : sentUpdate.status == 0 ? (
                <SyncLoader color="#36d7b7" />
              ) : sentUpdate.status == 1 ? (
                "Message Sent"
              ) : (
                "Message Sent un-successfull"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ContactPage ;

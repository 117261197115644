import styled from "styled-components";

const PC_Contianer = styled.div`
  height: 450px;
  width: 350x;
  padding: 0 1.5rem 1.5rem 1.5rem;
  display: flex;
  align-items: center;
  flex-flow:column;
 justify-content: space-evenly;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
const PC_ImgContainer = styled.div`
  height: 300px;
  width: 300px;
  border-radius: 50%;
`;
const PC_Logo = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
`;
const PC_Name = styled.h1`
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
`;
const PC_Designation = styled.h6`
  margin: 0;
  margin-block-start: 1rem;
  text-align: center;
  font-weight: 10;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
`;
const PC_SocialLinkWrapper = styled.div`
  display: flex;
`;

const PC_ND_Wrapper = styled.div``;
export {
  PC_Contianer,
  PC_ImgContainer,
  PC_Logo,
  PC_ND_Wrapper,
  PC_Name,
  PC_Designation,
  PC_SocialLinkWrapper,
};

import { Route, Routes } from "react-router-dom";
import { HFContainer } from "../pages/HFContaier/HFContainer";
import {HomePage,  HomepageSlide2, HomepageSlide3, HomepageSlide4 } from "../components/HomePage";
import {Vision,AboutLanding,ChooseUs,Team,About2,Team2,VisionJson,TeamDataJson} from "../components/About";
import { ScrollSetter } from "../components/CustomComponents";
import {ContactLandingPage, ContactPage} from "../components/ContactUs";
import {Service, ServiceLandingPage,ServiceJson} from "../components/Services";
import WelcomePage4 from "../components/WelcomePage4/welcomePage4";

const AppRoute = () => {
  return (
    <>
      <ScrollSetter />
      <Routes>
        <Route replace path="/" element={<WelcomePage4 />} />
        <Route
          path="/home"
          element={
            <HFContainer PageName="Home">
              <HomePage />
              <HomepageSlide4 />
              <HomepageSlide2 />
              <HomepageSlide3 /> 
            </HFContainer>
          }
        />
        <Route
          path="/About"
          element={
            <HFContainer PageName="About Us">
              <AboutLanding PageName="About Us" />
              <About2 />
              <ChooseUs />
              <Vision {...VisionJson["1"]} />
              <Team2 />
            </HFContainer>
          }
        />
        <Route
          path="/Contact"
          element={
            <HFContainer PageName="Contact Us">
              <ContactLandingPage PageName="Contact Us" />
              <ContactPage />
            </HFContainer>
          }
        />
        <Route
          path="/Services"
          element={
            <HFContainer PageName="Product & Services">
              <ServiceLandingPage PageName="Product & Service" />
              <Service {...ServiceJson["1"]}  />
              <Service {...ServiceJson["2"]}/>
              <Service { ...ServiceJson["3"]}  />
            </HFContainer>
          }
        />
      </Routes>
    </>
  );
};

export default AppRoute;

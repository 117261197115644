import styled  from "styled-components";

const InputContainer=styled.div`
position: relative;
width: 400px;
height: 70px;
margin: 0px;
margin: 15px;
box-sizing: border-box;
padding:0px;
font-family: 'Poppins',sans-serif;
`;


export {InputContainer}
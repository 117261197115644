import "./About.css";
import CUImg from "../../assests/CUImg.svg";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import {
  faCheckCircle,
  faChartLine,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ChooseUs = () => {
  return (
    <Box
      className="CU-container"
      sx={{
        boxSizing: "border-box",
        paddingBlock: "48px",
        paddingInline: "12px",
        width: "100%",
        color: "white"
      }}
    >
      <Grid
        container
        columns={12}
        sx={{
          height: "100%",
          margin: "0px",
          boxSizing: "border-box",
        }}
      >
        <Grid
          item
          md={6}
          xs={12}
          alignContent={""}
          sx={{ boxSizing: "border-box" }}
        >
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Button
                variant="outlined"
                sx={{
                  marginBlockEnd: { xs: "1rem", md: "2rem" },
                  marginBlockStart: "1rem",
                  backgroundColor: "rgb(249,124,92)",
                  color: "white",
                  border: "none",
                  borderRadius: "800px",
                }}
              >
                Our Mission
              </Button>
            </Box>
            <Typography
              variant="h1"
              fontWeight={700}
              sx={{
                marginBlockEnd: { xs: "1rem", md: "2rem" },
                fontSize: { xs: "30px", md: "40px" },
              }}
            >
              We're Best in AI Industry with 10 Years of Experience
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBlockEnd: { xs: "1rem", md: "2rem" } }}
            >
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor eirmod magna dolore erat amet
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                marginBlockEnd: { xs: "1rem", md: "2rem" },
              }}
            >
              <Grid
                container
                alignItems={"center"}
                item
                sx={{ marginBlockEnd: "1rem" }}
              >
                <Grid item sx={{ marginInlineEnd: "1rem" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="2xl"
                    color="rgb(249,124,92)"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    Diam dolor diam ipsum et tempor sit
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                item
                sx={{ marginBlockEnd: "1rem" }}
              >
                <Grid item sx={{ marginInlineEnd: "1rem" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="2xl"
                    color="rgb(249,124,92)"
                  />
                </Grid>
                <Grid>
                  <Typography variant="h6">
                    Diam dolor diam ipsum et tempor sit
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                item
                sx={{ marginBlockEnd: "1rem" }}
              >
                <Grid item sx={{ marginInlineEnd: "1rem" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="2xl"
                    color="rgb(249,124,92)"
                  />
                </Grid>
                <Grid>
                  <Typography variant="h6">
                    Diam dolor diam ipsum et tempor sit
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                item
                sx={{ marginBlockEnd: "1rem" }}
              >
                <Grid item sx={{ marginInlineEnd: "1rem" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="2xl"
                    color="rgb(249,124,92)"
                  />
                </Grid>
                <Grid>
                  <Typography variant="h6">
                    Diam dolor diam ipsum et tempor sit
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                item
                sx={{ marginBlockEnd: "1rem" }}
              >
                <Grid item sx={{ marginInlineEnd: "1rem" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="2xl"
                    color="rgb(249,124,92)"
                  />
                </Grid>
                <Grid>
                  <Typography variant="h6">
                    Diam dolor diam ipsum et tempor sit
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              {/* <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  paddingInlineStart: "1rem",
                  flexGrow: "1 1 auto",
                  borderRadius: "10px",
                  backgroundColor: "rgb(85,85,85,0.4)",
                  paddingInlineEnd: "2rem",
                  paddingBlock: "1rem",
                  marginBlockEnd: "1rem",
                }}
              >
                <FontAwesomeIcon
                  icon={faChartLine}
                  size="3x"
                  style={{ alignSelf: "center" }}
                  color="rgb(249,124,92)"
                />
                <Box sx={{ marginInlineStart: "2rem", alignSelf: "center" }}>
                  <Typography variant="h4">999</Typography>
                  <Typography variant="body1">Happy Clients</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  paddingInlineStart: "1rem",
                  borderRadius: "10px",
                  backgroundColor: "rgb(85,85,85,0.4)",
                  flexGrow: "1 1 auto",
                  paddingInlineEnd: "2rem",
                  paddingBlock: "1rem",
                  marginBlockEnd: "1rem",
                }}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  size="3x"
                  style={{ alignSelf: "center" }}
                  color="rgb(249,124,92)"
                />
                <Box sx={{ marginInlineStart: "2rem", alignSelf: "center" }}>
                  <Typography variant="h4">999</Typography>
                  <Typography variant="body1">Happy Clients</Typography>
                </Box>
              </Box> */}
            </Box>
          </Container>
        </Grid>
        <Grid item md={6} xs={12} sx={{ boxSizing: "border-box" }}>
          <Container
            sx={{
              height: "100%",
              paddingBlockStart: "48px",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <img src={CUImg} style={{ objectFit: "fill" }} width={"500px"} />
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChooseUs;
